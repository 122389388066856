var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"publications-new-table main-content"},[_vm._m(0),_c('div',{staticClass:"publications-new-table__body"},_vm._l((_vm.paginatedItems),function(item){return _c('router-link',{key:item.id,staticClass:"publications-new-table__row",attrs:{"to":{ name: 'PublicationsArticle', params: { id: item.id } }}},[_c('div',{staticClass:"publications-new-table__value main-table__column"},[_c('p',[_vm._v(_vm._s(item.id.toLocaleString()))])]),_c('div',{staticClass:"publications-new-table__value main-table__column"},[_c('p',[_vm._v(_vm._s(item.title))])]),_c('div',{staticClass:"publications-new-table__value main-table__column"},[_c('ul',{staticClass:"main-table__list main-table__list_direction"},_vm._l((item.availableCountries),function(country){return _c('li',{key:country},[_c('div',{staticClass:"main-table__icon"},[_c('img',{attrs:{"src":require(`@/assets/images/icons/flags/${country.toLowerCase()}.svg`),"alt":""}})]),_c('p',{staticClass:"main-table__text main-table__text_bold"},[_vm._v(" "+_vm._s(country)+" ")])])}),0)]),_c('div',{staticClass:"publications-new-table__value main-table__column"},[_c('div',{staticClass:"main-table-author"},[_c('div',{staticClass:"main-table-author__image"},[_c('img',{attrs:{"src":require("@/assets/images/users/avatar2.png"),"alt":"user"}})]),_c('div',{staticClass:"main-table-author__info"},[_c('p',{staticClass:"main-table-author__name"},[_vm._v(" "+_vm._s(_vm.getAuthor(item.authorId) ? _vm.getAuthor(item.authorId).name : "")+" "+_vm._s(_vm.getAuthor(item.authorId) ? _vm.getAuthor(item.authorId).surname : "")+" ")]),_c('p',{staticClass:"main-table-author__tag"},[_vm._v(" "+_vm._s(_vm.getAuthor(item.authorId) ? _vm.getAuthor(item.authorId).tag : "")+" ")]),_c('ul',{staticClass:"main-table-author__list"},_vm._l((_vm.getAuthor(item.authorId)
                  ? _vm.getAuthor(item.authorId).roles
                  : []),function(role){return _c('li',{key:role,style:([
                  role == 'Лидер мнений'
                    ? { background: '#CEE9FF' }
                    : { background: '#E2E2E2' },
                ])},[_c('p',[_vm._v(_vm._s(role))])])}),0)])])]),_c('div',{staticClass:"publications-new-table__value main-table__column"},[_c('p',[_vm._v(" "+_vm._s(new Date(item.publish).toLocaleDateString())+" "+_vm._s(new Date(item.publish).toLocaleTimeString().slice(0, -3))+" ")])]),_c('div',{staticClass:"publications-new-table__value main-table__column"},[_c('p',[_vm._v(_vm._s(new Date(item.created).toLocaleDateString()))])])])}),1),_c('Paginator',{attrs:{"items":_vm.filteredPublications},on:{"changePaginatedItems":_vm.changeData}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"publications-new-table__top main-table_white"},[_c('div',{staticClass:"publications-new-table__row"},[_c('div',{staticClass:"publications-new-table__parameter main-table__column"},[_c('p',[_vm._v("Id")])]),_c('div',{staticClass:"publications-new-table__parameter main-table__column"},[_c('p',[_vm._v("Заголовок")])]),_c('div',{staticClass:"publications-new-table__parameter main-table__column"},[_c('p',[_vm._v("Страны")])]),_c('div',{staticClass:"publications-new-table__parameter main-table__column"},[_c('p',[_vm._v("Автор")])]),_c('div',{staticClass:"publications-new-table__parameter main-table__column"},[_c('p',[_vm._v("Опубликовать")])]),_c('div',{staticClass:"publications-new-table__parameter main-table__column"},[_c('p',[_vm._v("Создано")])])])])
}]

export { render, staticRenderFns }